import React, { FC } from "react";
import Fade from "react-reveal/Fade";
import { Box, Flex, Heading, Text } from "rebass";
import { css } from "styled-components";
import { contacts } from "../lib/config";
import { theme } from "../lib/theme";
import Button from "./Button";

type Props = {
  secondary?: boolean;
  light?: boolean;
};

const lightStyle = css`
  position: relative;
  bottom: -4rem;
  background: white;
  max-width: 1200px;
  margin: 0 auto;
`;

const CallToAction: FC<Props> = ({ light, secondary }) => (
  <Fade>
    <Box
      width={1}
      px={4}
      py={[6, 6, 7]}
      css={`
        background: rgb(41, 110, 180);
        background: linear-gradient(
          132deg,
          rgba(41, 110, 180, 1) 51%,
          rgba(52, 152, 219, 1) 94%
        );
        ${light && lightStyle}
      `}
    >
      <Flex
        width={1}
        mx="auto"
        flexWrap="wrap"
        justifyContent="space-between"
        alignItems={["center"]}
        css={`
          max-width: 1200px;
        `}
      >
        <Box>
          <Heading
            fontSize={[5, "54px"]}
            color={light ? theme.colors.primary : "white"}
          >
            與我們的移民顧問聯絡
          </Heading>
          <Text
            fontSize={[3, "28px"]}
            color={light ? theme.colors.info : "rgba(255,255,255,0.6)"}
          >
            為你訂造合適你的移民計劃
          </Text>
        </Box>
        <Button
          as="a"
          rel="noopener"
          href={contacts.whatsapp.link}
          target="_blank"
          variant={light ? "primary" : "light"}
          fontSize={[2, 3]}
          mt={[3, 3, 0]}
          width={[1, 1 / 3, 1 / 5]}
        >
          免費諮詢
        </Button>
      </Flex>
    </Box>
  </Fade>
);

export default CallToAction;
