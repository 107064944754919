import { navigate } from "gatsby"
import React, { FC } from "react"
import { BoxProps, Card, Flex } from "rebass"
import Button from "../../components/Button"
import Section from "../../components/Section"
import MigrationStep, { StepText } from "./migrationStep.style"

const migrationStep = [
  "到希臘考察房產",
  "會見律師，簽署授權書。授權律師進行查察，代辦個人稅號及開立銀行賬戶等",
  "律師完成查察工作，確定沒有產權問題，投資者可付定金",
  "工程師完成查察工作，確定沒有僭建違規問題，投資者可支付尾數",
  "完成房產交易，律師註冊新投資者名字於政府田土廳",
  "律師遞交移民資料",
]

type Props = {
  showButton?: boolean
}

const MigrationStepSection: FC<Props & BoxProps> = ({
  showButton,
  ...rest
}) => {
  return (
    <Section px={[1, 4]} maxWidth="720px" title="移民流程" divider {...rest}>
      {migrationStep.map((s, i) => (
        <MigrationStep step={i + 1} key={`migration-step-${i}`}>
          <StepText>{s}</StepText>
        </MigrationStep>
      ))}
      <MigrationStep step="7">
        <StepText>完成房產交易後大約</StepText>
        <StepText
          color="danger"
          fontWeight="bold"
          fontSize={["1.2rem", "2.5rem"]}
        >
          {" "}
          2個月後{" "}
        </StepText>
        <StepText>拿到居留卡</StepText>
      </MigrationStep>

      {showButton && (
        <Flex mt={4} justifyContent="center">
          <Button
            onClick={() => {
              navigate("/need-to-know/")
            }}
          >
            查看更多
          </Button>
        </Flex>
      )}
    </Section>
  )
}

export default MigrationStepSection
