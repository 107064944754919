import { graphql, useStaticQuery } from "gatsby";
import React, { FC } from "react";
import ReactPlayer from "react-player";
import { Box, BoxProps } from "rebass";
import { borderRadius } from "styled-system";
import IconText from "../../components/IconText";
import Section, { SectionHeader } from "../../components/Section";
import BorderRadiusBox from "../../components/StyledComponent/BorderRadiusBox";
import DisplayFlex from "../../components/StyledComponent/DisplayFlex";
import ViewMore from "../../components/ViewMore";

const needToKnows = [
  "年滿 18 歲，無年齡上限要求，無學歷及語言要求",
  "在希臘購買 25 萬歐元以上的房產，包括：住宅、商舖、寫字樓、農田、廠房、倉庫或住宅建設用地等",
  "有至少一次入境希臘的記錄",
  "申請本人、配偶; 本人及配偶的父母; 未滿 21 歲的子女均可獲得居留卡",
  "無移民監，無需在希臘居住"
];

interface Props extends BoxProps {
  showButton?: boolean;
}

const NeedToKnow: FC<Props> = ({ showButton, ...rest }) => {
  const { youtubePreview } = useStaticQuery(graphql`
    {
      youtubePreview: file(relativePath: { eq: "youtube-preview.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Section fluid {...rest}>
      <>
        <DisplayFlex
          mx={[0, 0, 4]}
          display={["flex", "flex", "table"]}
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          flexDirection={["column-reverse", "column-reverse", "row"]}
        >
          <BorderRadiusBox
            width={[1, 1, 1 / 2]}
            pt={["64.25%", "64.25%", "34.25%"]}
            borderRadius={[0, 0, 12]}
            boxShadow={[
              "none",
              "none",
              "0px 0px 41px 16px rgba(0, 0, 0, 0.14)"
            ]}
            css={`
              ${borderRadius}
              display: table-cell;
              position: relative;
              overflow: hidden;

              .react-player {
                position: absolute;
                top: 0%;
                left: 0;
              }

              .react-player__shadow {
                background: rgb(236, 68, 68) !important;
                width: 90px !important;
                border-radius: 16px !important;
              }
            `}
          >
            <ReactPlayer
              url="https://www.youtube.com/watch?v=97baVJCZXHM"
              playing
              controls
              className="react-player"
              width="100%"
              light={youtubePreview.childImageSharp.fluid.src}
              // height={["45vh", "85vh"]}
              height="100%"
            />
          </BorderRadiusBox>

          <Box
            width={[1, 1, 1 / 2]}
            pr={2}
            pl={[2, 4, "5rem"]}
            pb={["4rem", "4rem", 0]}
            css={`
              display: table-cell;
              vertical-align: middle;
            `}
          >
            <SectionHeader textAlign={["center", "center", "left"]}>
              移民須知
            </SectionHeader>
            {needToKnows.map((p, i) => (
              <IconText key={`need-to-know-${i}`}>{p}</IconText>
            ))}
          </Box>
        </DisplayFlex>
        {showButton && <ViewMore path="/need-to-know/" />}
      </>
    </Section>
  );
};

export default NeedToKnow;
