import { Box } from "rebass";
import styled from "styled-components";
import {
  borderRadius,
  BorderRadiusProps,
  boxShadow,
  BoxShadowProps
} from "styled-system";
import { theme } from "../../lib/theme";

const BorderRadiusBox = styled(Box)<BorderRadiusProps & BoxShadowProps>`
  ${borderRadius}
  ${boxShadow}
  overflow: hidden;
`;

BorderRadiusBox.defaultProps = {
  borderRadius: [0, 0, 12],
  boxShadow: ["none", "none", theme.shadows.small],
  width: 1
};

export default BorderRadiusBox;
