import { useQuery } from "@apollo/react-hooks";
import { Link } from "gatsby";
import gql from "graphql-tag";
import React, { FC } from "react";
import Box from "../../components/elements/Box";
import Image from "../../components/elements/Image";
import Text from "../../components/elements/Text";
import FeatureBlock from "../../components/FeatureBlock";
import GlideCarousel from "../../components/GlideCarousel";
import GlideSlide from "../../components/GlideCarousel/glideSlide";
import ViewMore from "../../components/ViewMore";
import { serverUrl } from "../../lib/config";
import { formatPrice } from "../../lib/formatPrice";
import SectionWrapper, { NextButton, PrevButton } from "./property.style";

const carouselOptions = {
  type: "carousel",
  autoplay: 4000,
  perView: 3,
  gap: 4,
  animationDuration: 800,
  breakpoints: {
    990: {
      perView: 3
    },
    776: {
      perView: 2
    },
    576: {
      perView: 1
    }
  }
};

type Props = {
  showButton?: boolean;
};

const PropertySection: FC<Props> = ({ showButton = false, ...rest }) => {
  const { loading, data, error } = useQuery(gql`
    {
      properties(
        pids: [
          "01-2621"
          "01-2002"
          "01-2936"
          "01-2973"
          "01-1939"
          "01-2198"
          "01-1902"
          "01-2595"
          "01-1594"
          "01-2641"
          "01-1157"
          "01-1896"
          "01-2654"
          "01-2533"
          "01-2811"
          "01-494"
          "01-2665"
        ]
      ) {
        items {
          pid
          imageUrls
          title
          size
          price
        }
      }
    }
  `);

  if (
    loading ||
    (data &&
      data.properties &&
      data.properties.items &&
      data.properties.items.length === 0) ||
    error
  )
    return null;

  return (
    <SectionWrapper title="精選樓盤">
      <GlideCarousel
        carouselSelector="property-carousel"
        options={carouselOptions}
        prevButton={
          <PrevButton>
            <span />
          </PrevButton>
        }
        nextButton={
          <NextButton>
            <span />
          </NextButton>
        }
      >
        <>
          {loading && data.properties ? (
            <div />
          ) : (
            data.properties.items.map((property, i) => (
              <GlideSlide key={`property-item-${i}`}>
                <Link to={`/properties/p?pid=${property.pid}`}>
                  <FeatureBlock
                    className="property__item"
                    icon={
                      <Image
                        src={`${serverUrl}${property.imageUrls[0]}`}
                        alt={`Property Image ${i}`}
                        className="property__image"
                      ></Image>
                    }
                    title={
                      <Text
                        as="p"
                        content={property.title}
                        fontSize={[3]}
                        fontWeight={400}
                        color="white"
                        lineHeight="1.5"
                        letterSpacing="-0.0001rem"
                        pl={2}
                        mb={1}
                      />
                    }
                    description={
                      <Box flexBox justifyContent="space-between" px={2} mb={2}>
                        <Text
                          as="span"
                          content={property.size}
                          fontSize={[3]}
                          lineHeight="1"
                          color="rgba(255, 255, 255, 0.8)"
                        />
                        <Text
                          as="span"
                          content={formatPrice(property.price)}
                          fontSize={[3]}
                          lineHeight="1"
                          color="rgba(255, 255, 255, 0.8)"
                        />
                      </Box>
                    }
                  ></FeatureBlock>
                </Link>
              </GlideSlide>
            ))
          )}
        </>
      </GlideCarousel>
      {showButton && <ViewMore mt={0} label="查看更多" path="/properties/" />}
    </SectionWrapper>
  );
};

export default PropertySection;
