import React from "react";
import { Icon } from "react-icons-kit";
import { checkmark } from "react-icons-kit/icomoon/checkmark";
import Box from "../components/elements/Box";
import Text from "../components/elements/Text";

const IconText = ({ children, icon = checkmark, size = 28 }) => {
  return (
    <Box flexBox>
      <Icon
        icon={icon}
        css={`
          color: ${props => props.theme.colors.info};
        `}
        size={size}
      />
      <Text
        width={"90%"}
        ml={2}
        mb={2}
        fontSize={[2, 3]}
        content={children}
      ></Text>
    </Box>
  );
};

export default IconText;
