import styled from "styled-components";
import Section from "../../components/Section";

const SectionWrapper = styled(Section)`
  .property__item {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 250px;
    transition: all 0.3s ease;

    .icon__wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content__wrapper {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      background-color: rgba(0, 0, 0, 0.9);
      background: linear-gradient(transparent, rgba(0, 0, 0, 0.5));
      transition: all 0.3s ease;
      a,
      span {
        transform: translateY(50px);
        transition: all 0.3s ease;
      }
      p {
        transform: translateY(50px);
        transition: all 0.35s ease;
      }
    }

    &:hover {
      .content__wrapper {
        opacity: 1;
        visibility: visible;
        a,
        p,
        span {
          transform: translateY(0);
        }
      }
    }
    &:first-child {
      .content__wrapper {
        @media (max-width: 990px) {
          opacity: 1;
          visibility: visible;
          a,
          p,
          span {
            transform: translateY(0);
          }
        }
      }
    }
  }
`;

export const PrevButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: ${props => props.theme.colors.info};
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: "";
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(-45deg);
      top: -4px;
      left: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(45deg);
      width: 8px;
      bottom: -6px;
      left: 1px;
    }
  }
`;

export const NextButton = styled.div`
  position: relative;
  padding: 18px 10px;
  cursor: pointer;
  &:hover {
    span {
      background: ${props => props.theme.colors.info};
      @media (min-width: 991px) {
        width: 100px;
      }
    }
  }
  span {
    width: 18px;
    height: 2px;
    background: #d1d3de;
    display: block;
    position: relative;
    transition: 0.3s cubic-bezier(0.445, 0.05, 0.55, 0.95);
    &:before,
    &:after {
      content: "";
      display: block;
      height: 2px;
      border-radius: 2px;
      background: inherit;
      position: absolute;
    }
    &:before {
      transform: rotate(45deg);
      top: -4px;
      right: 0;
      width: 10px;
    }
    &:after {
      transform: rotate(-45deg);
      width: 8px;
      bottom: -6px;
      right: 1px;
    }
  }
`;

export default SectionWrapper;
