import React, { FC } from "react"
import Box, { BoxProps } from "../../components/elements/Box"
import Button from "../../components/Button"
import { navigate } from "gatsby"

type Props = {
  path: string
  label?: string
}

const ViewMore: FC<Props & BoxProps> = ({ path, label, ...rest }) => {
  return (
    <Box flexBox justifyContent="center" {...rest}>
      <Button
        onClick={() => {
          navigate(path)
        }}
      >
        {label}
      </Button>
    </Box>
  )
}

ViewMore.defaultProps = {
  path: "/",
  label: "查看更多",
  mt: 4,
}
export default ViewMore
