import React, { FC } from "react"
import Box from "../../components/elements/Box"
import Text from "../../components/elements/Text"
import styled from "styled-components"
import { fontSize, height, HeightProps, width } from "styled-system"

const MigrationStep = ({ step, children }) => {
  return (
    <Box flexBox mx={1} my={4} alignItems={["center"]}>
      <Box width={1 / 6}>
        <Circle
          fontWeight="bold"
          fontSize={["28px", "32px"]}
          height={["48px", "66px"]}
          width={["48px", "66px"]}
        >
          {step}
        </Circle>
      </Box>
      <Box width={5 / 6} ml={[3, 1]}>
        {children}
      </Box>
    </Box>
  )
}

export const StepText = ({ children, ...rest }) => (
  <Text
    // fontWeight={"bold"}
    fontSize={[2, 3]}
    lineHeight="1.5"
    // pt={"5px"}
    css={`
      display: inline;
    `}
    {...rest}
    content={children}
  ></Text>
)

export default MigrationStep

const Circle: any = styled.div`
  ${height}
  ${width}
  ${fontSize}
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  border: 5px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.primary};
  `

const Line: FC<HeightProps> = styled(Box)<HeightProps>`
  ${height}
  width: 2px;
  background: ${props => props.theme.colors.border};
`
